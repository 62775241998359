var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.$apollo.loading || _vm.mutationLoading,"rounded":"sm"}},[_c('div',{staticClass:"custom-search d-flex justify-content-start"},[_c('b-form-group',{staticClass:"form-inline",attrs:{"label":"Search","label-size":"sm"}},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Search meeting","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"pagination-options":{
        enabled: true,
        perPage:_vm.pageLength
      },"rows":_vm.rows,"search-options":{
        enabled: true,
        externalQuery: _vm.searchTerm }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'title')?_c('span',[_vm._v(" "+_vm._s(props.row.title)+" "),_c('br'),_vm._v(" "),_c('small',{staticClass:"text-capitalize"},[_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(_vm._s(props.row.type))])],1)]):(props.column.field === 'buttons')?_c('span',{staticClass:"d-flex"},[_c('b-button',{staticClass:"mr-50",attrs:{"size":"sm","variant":"outline-primary","to":{
              name: 'events-program-manager',
              params: { id: props.row.id },
            }}},[_vm._v(" Manage Event ")])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap "},[_vm._v(" Showing 1 to ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['3','5','10']},on:{"input":function (value){ return props.perPageChanged({currentPerPage:value}); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"per-page":_vm.pageLength,"total-rows":props.total,"value":1,"first-number":"","last-number":"","next-class":"next-item","prev-class":"prev-item"},on:{"input":function (value){ return props.pageChanged({currentPage:value}); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }