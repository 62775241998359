<template>
  <b-card id="card-navigation">
    <b-card-header
      class="justify-content-start p-0 pb-1 mb-1 border-bottom"
    >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-secondary"
        @click="$router.back()"
      >
        <feather-icon
          class="mr-25"
          icon="ChevronLeftIcon"
        />
      </b-button>
      <h3 class="mb-0 ml-2 d-flex justify-space-between">
        <span>Manage {{ incubatorName }}</span>
      </h3>
    </b-card-header>
    <!-- pill tabs inside card -->

    <b-tabs
      v-model="activeTab"
      nav-class="mb-3"
      class="h-scroll-tabs"
      pills
      @input="updateHash"
    >
      <b-tab
        class="text-left"
        href="Mentors"
        title="Mentors"
        lazy
      >
        <Mentors />
      </b-tab>
      <b-tab
        class="text-left"
        href="Sessions"
        title="Sessions"
        lazy
      >
        <EMeet />
      </b-tab>
      <b-tab
        class="text-left"
        href="Events"
        title="Events"
        lazy
      >
        <Events />
      </b-tab>
      <b-tab
        class="text-left"
        href="Competitions"
        title="Competitions"
        lazy
      >
        <Competitions />
      </b-tab>
      <b-tab
        class="text-left"
        href="Programs"
        title="Programs"
        lazy
      >
        <Programs />
      </b-tab>
      <b-tab
        class="text-left"
        href="Requests"
        title="Requests"
        lazy
      >
        <Requests />
      </b-tab>
      <b-tab
        class="text-left"
        href="Feedbacks"
        title="Feedbacks"
        lazy
      >
        <Feedback />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BTab, BTabs, BCard, BButton, BCardHeader,
} from 'bootstrap-vue'

// Skill-Dev Programs
import Programs from '@/views/student-portal/pages/incubators/incubator-manager/Programs.vue'

import Requests from '@/views/startup-portal/pages/incubators/incubator-manager/Requests.vue'
import Mentors from '@/views/startup-portal/pages/incubators/incubator-manager/Mentors.vue'
import EMeet from '@/views/startup-portal/pages/incubators/incubator-manager/Emeet.vue'
import Events from '@/views/startup-portal/pages/incubators/incubator-manager/Events.vue'
import Competitions from '@/views/startup-portal/pages/incubators/incubator-manager/Competitions.vue'
import Feedback from '@/views/startup-portal/pages/incubators/incubator-manager/Feedback.vue'
import gql from 'graphql-tag'

export default {
  components: {
    BCardHeader,
    BButton,
    EMeet,
    Events,
    Competitions,
    Programs,
    Feedback,
    Requests,
    Mentors,
    BTab,
    BTabs,
    BCard,
  },
  data() {
    return {
      activeTab: 0,
      tabs: ['#Mentors',
        '#Sessions',
        '#Events',
        '#Competitions',
        '#Programs',
        '#Requests',
        '#Feedbacks'],
      incubatorName: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.activeTab = this.tabs.findIndex(tab => tab === this.$route.hash)
    })
  },
  methods: {
    updateHash(e) {
      this.$router.replace(this.$route.path + this.tabs[e])
    },
  },
  apollo: {
    incubatorName: {
      query() {
        return gql`
           query incubator_name_from_assoc_id{
           users_associationtable_by_pk(id: ${this.$route.params.id} ) {
             usersOrganizationtableByIncubator {
                title
                registered_title
             }
            }
           }`
      },
      update: data => data.users_associationtable_by_pk.users_organizationtable.title || data.users_associationtable_by_pk.users_organizationtable.registered_title,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
